// extracted by mini-css-extract-plugin
export const root = "PlasmicPricing-module--root--rcns1";
export const box__oXv6G = "PlasmicPricing-module--box__oXv6G--31dvC";
export const header = "PlasmicPricing-module--header--arUMc";
export const section___3EkHb = "PlasmicPricing-module--section___3EkHb--fmssY";
export const box__rRFsw = "PlasmicPricing-module--box__rRFsw--IOL4B";
export const plan__zwEw1 = "PlasmicPricing-module--plan__zwEw1--379dA";
export const box__gupEv = "PlasmicPricing-module--box__gupEv--3u-WX";
export const plan__s0PBk = "PlasmicPricing-module--plan__s0PBk--1Zjga";
export const box___9BpXa = "PlasmicPricing-module--box___9BpXa--1ZW5-";
export const box__mHxJm = "PlasmicPricing-module--box__mHxJm--1rDzx";
export const bullet__pG00C = "PlasmicPricing-module--bullet__pG00C--2FAu5";
export const bullet__deNqC = "PlasmicPricing-module--bullet__deNqC--2uHPH";
export const bullet__rupVf = "PlasmicPricing-module--bullet__rupVf--39FBy";
export const bullet__kwgyS = "PlasmicPricing-module--bullet__kwgyS--RR6FQ";
export const plan__bZpZv = "PlasmicPricing-module--plan__bZpZv--KzGgk";
export const box__y5YHx = "PlasmicPricing-module--box__y5YHx--22d7N";
export const section__xMy3T = "PlasmicPricing-module--section__xMy3T--3qlZD";
export const faq__n5VN5 = "PlasmicPricing-module--faq__n5VN5--2Z13Z";
export const faq__za2CH = "PlasmicPricing-module--faq__za2CH--3FIdc";
export const faq__ifBhf = "PlasmicPricing-module--faq__ifBhf--ItIM3";
export const footer = "PlasmicPricing-module--footer--k54-O";